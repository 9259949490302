import { Controller } from 'stimulus';
import { enter, leave } from 'el-transition'

export default class extends Controller {
    static outlets = [ "address" ]
    static targets = [ "jobLocationSelect" ]

    addressOutletConnected(outlet, element) {
        if (this.jobLocationSelectTarget.value === 'on site' || this.jobLocationSelectTarget.value === 'hybrid') {
            enter(element)
        }
    }

    changedLocationType(event) {
        if (event.currentTarget.value === 'on site' || this.jobLocationSelectTarget.value === 'hybrid') {
            enter(this.addressOutletElement)
        } else {
            leave(this.addressOutletElement)
        }
    }
}
