import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'
import { enter, leave } from 'el-transition'
const TokenGenerator = require('uuid-token-generator')

const li = function(value) {
    return document.createRange().createContextualFragment(`<li
        role="option"
        class="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9 capitalize leading-6 text-base hover-bg-secondary"
        data-address-target="li"
        data-action="click->address#selectOption"
        data-address-value-param="${value[0]}"
        data-address-placeId-param="${value[1]}"
        >
        ${value[0]}
    </li>`)
}
export default class extends Controller {
    static targets = [ "searchInput", "dropdown", "ul", "li", "countryField", "stateField", "cityField", "latField", "lngField" ]
    static values = { sessionToken: String }

    connect() {
        useClickOutside(this);
    }

    clickOutside(event) {
        this.closeDropdown()
        if(this.hasLiTarget && (this.countryFieldTarget.value === null || this.countryFieldTarget.value === "")) {
            this.liTarget.click();
        }
    }

    search() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            if (this.searchInputTarget.value.length >= 3) {
                this.autocompleteRequest(this.searchInputTarget.value)
            } else {
                this.ulTarget.innerHTML = ''
                leave(this.dropdownTarget)
            }
        }, 200)
    }

    inputClick() {
        this.search()
    }

    autocompleteRequest(value) {
        let sessionToken
        if (this.hasSessionTokenValue) {
            sessionToken = this.sessionTokenValue
        } else {
            sessionToken = new TokenGenerator().generate();
            this.sessionTokenValue = sessionToken
        }
        const url = `/api/v1/google_autocomplete?value=${value}&session_token=${sessionToken}`
        fetch(url)
            .then(response => response.json())
            .then(data => this.populateAutocompleteDropdown(data))
            .catch((error) => console.error('Error:', error))
    }

    populateAutocompleteDropdown(data) {
        this.ulTarget.innerHTML = '';
        data.forEach((el) => this.ulTarget.appendChild(li(el)))
        enter(this.dropdownTarget)
    }

    closeDropdown() {
        leave(this.dropdownTarget)
    }

    selectOption({ params: { value, placeid } }) {
        this.searchInputTarget.value = value
        this.closeDropdown()
        this.getDetailedPlaceInfo(placeid)
    }

    getDetailedPlaceInfo(placeid) {
        let sessionToken
        if (this.hasSessionTokenValue) {
            sessionToken = this.sessionTokenValue
            delete this.element.dataset.addressSessionTokenValue
        } else {
            sessionToken = new TokenGenerator().generate()
        }
        const url = `/api/v1/google_place?placeid=${placeid}&session_token=${sessionToken}`
        fetch(url)
            .then(response => response.json())
            .then(data => this.populateInputs(data))
            .catch((error) => console.error('Error:', error))
    }

    populateInputs({ country, state, city, lat, lng }) {
        this.countryFieldTarget.value = country
        this.stateFieldTarget.value = state
        this.cityFieldTarget.value = city
        this.latFieldTarget.value = lat
        this.lngFieldTarget.value = lng
    }
}
